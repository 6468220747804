<template>
  <svg data-name="Layer 1" version="1.1" width="100" height="23" viewBox="0 0 575.51 129.66" xmlns="http://www.w3.org/2000/svg" xmlns:cc="http://creativecommons.org/ns#" xmlns:dc="http://purl.org/dc/elements/1.1/" xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#">
    <path class="cls-1" d="m94.229 11.644-14.43 14.43c-1 1-2.44 0-2.44 0l-18.5-18.81a15.31 15.31 0 0 0-21.58 0 15.29 15.29 0 0 0 0 21.58l30.65 30.66a15.31 15.31 0 0 0 21.59 0l25.65-25.65 4.49-4.61a7.88 7.88 0 0 1 11.15 0 7.59 7.59 0 0 1-0.07 10.9l-4.63 4.66-46.59 46.61a1.92 1.92 0 0 1-2.8 0l-50.68-50.68a15.32 15.32 0 0 0-21.59 0 15.31 15.31 0 0 0 0 21.58l63.19 63.19-0.33-0.29a15.31 15.31 0 0 0 21.58 0l59.45-59.45a38.27 38.27 0 1 0-54.11-54.12z" />
    <path class="cls-2" d="m242.28 104.19h-33.73v-54.35a2.25 2.25 0 0 0-4.49 0v56.25a2.29 2.29 0 0 0 2.24 2.24h36a2.07 2.07 0 0 0 0-4.14z" />
    <path class="cls-2" d="m289.98 49.504a3.17 3.17 0 0 0-3-2.16h-0.17a3.15 3.15 0 0 0-3 2.16l-25.8 55.9a3.14 3.14 0 0 0-0.34 1.38 2 2 0 0 0 2.07 1.89 2.29 2.29 0 0 0 2.16-1.63l7.07-15.62h35.59l7.07 15.49a2.58 2.58 0 0 0 2.24 1.72 2.11 2.11 0 0 0 2.16-2.06 2.91 2.91 0 0 0-0.35-1.38zm-19.12 37.87 16-34.94 15.87 34.94z" />
    <path class="cls-2" d="m370.72 47.604a2.44 2.44 0 0 0-2 1.29l-22 31.49-21.86-31.47a2.42 2.42 0 0 0-2.07-1.29 2.36 2.36 0 0 0-2.33 2.24 2.85 2.85 0 0 0 0.43 1.38l23.55 33.21v22a2.25 2.25 0 0 0 4.49 0v-22.1l23.37-32.87a3.29 3.29 0 0 0 0.69-1.72 2.33 2.33 0 0 0-2.27-2.16z" />
    <path class="cls-2" d="m429.12 76.764c4.31-2.42 7.93-6.21 7.93-13v-0.17a13.86 13.86 0 0 0-4.14-10.27c-3.45-3.45-8.88-5.35-15.78-5.35h-21.4a6.59 6.59 0 0 0-6.64 6.65v47.1a6.59 6.59 0 0 0 6.64 6.64h22.13c13.63 0 22.6-5.52 22.6-16.56v-0.18c-0.04-8.13-4.35-12.19-11.34-14.86zm-27.09-17.17h13.11c5.61 0 8.72 2.24 8.72 6.21v0.17c0 4.49-3.71 6.39-9.58 6.39h-12.25zm25.19 30.54c0 4.48-3.54 6.55-9.4 6.55h-15.79v-13.28h15.36c6.81 0 9.83 2.5 9.83 6.56z" />
    <path class="cls-2" d="m500.71 47.434a6.59 6.59 0 0 0-6.64 6.64v28.47c0 9.57-4.91 14.49-13 14.49s-13-5.09-13-14.92v-28a6.65 6.65 0 0 0-13.29 0v28.38c0 17.77 9.93 26.83 26.14 26.83s26.49-9 26.49-27.26v-27.99a6.59 6.59 0 0 0-6.7-6.64z" />
    <path class="cls-2" d="m569.03 47.344c-2.76 0-4.74 1.55-6.38 4l-14.92 21.31-14.67-21.17c-1.64-2.41-3.54-4.05-6.56-4.05a6.48 6.48 0 0 0-6.64 6.38 7.89 7.89 0 0 0 1.64 4.75l19.5 26.48v17.17a6.64 6.64 0 0 0 13.28 0v-17.43l19.5-26.31a8.28 8.28 0 0 0 1.72-4.66 6.15 6.15 0 0 0-6.47-6.47z" />
  </svg>
</template>

<style>
.cls-1{fill:#786dff}.cls-2{fill:#999}
</style>
