<template>
  <div v-if="product.name" class="pb-20">
    <!--
    <cgn-breadcrumb v-for="(breadcrumb, index) in breadcrumbs" :key="index" :links="breadcrumb" class="hidden md:block" />
    -->

    <div
      class="flex flex-col items-stretch justify-center px-4 2xl:container md:flex-row md:px-6 2xl:mx-auto 2xl:px-20 "
    >
      <div class="flex flex-col items-stretch md:w-2/3 lg:mr-3 lg:w-full xl:justify-between">
        <div class="relative mx-auto mt-5 w-full text-base lg:mr-5">
          <figure>
            <div v-if="showVideo">
              <video :src="product.video.file" :poster="product.video.slate" autoplay loop controls class="w-full" />
            </div>
            <div v-else>
              <div v-for="(image, index) in product.images" :key="index" class="rounded-lg bg-white">
                <cgn-lazy-image
                  v-if="image_index == index" class="rounded-lg shadow-lg" image-class="w-full"
                  :image="image" :width="image_width"
                />
              </div>
            </div>
            <div v-if="product.images.length > 1" class="mt-2 grid grid-cols-4 gap-4">
              <img
                v-for="(image, index) in product.images" :key="index" class="
                aspect-square
                w-full
                cursor-pointer
                overflow-hidden
                rounded-lg
                object-cover
                object-center
                shadow-lg
                hover:shadow-xl
                " :src="image.url" @click="swapImage(index)"
              >
              <div v-if="product.video" class="relative cursor-pointer" @click="playVideo()">
                <img
                  class="
                aspect-square
                w-full
                overflow-hidden
                rounded-lg
                object-cover
                object-center
                shadow-lg
                hover:shadow-xl
                " :src="product.video.slate"
                >
                <i-material-symbols:play-arrow-outline-rounded class="absolute top-0 h-full w-full" />
              </div>
            </div>
          </figure>
        </div>
        <div class="hidden w-full flex-col items-start justify-start pb-12 md:flex lg:hidden">
          <div class="jusitfy-start mt-8 flex items-center space-x-4 xl:mt-10">
            <!-- Start Share -->
            <cgn-social-share
              :url="`/product/${product.slug}`" :title="product.name" :description="product.name"
              :image="product.image.url" :twitter="true" :facebook="true" :linkedin="true" class="mt-3 text-xl"
            />
            <!-- End Share -->
          </div>
          <!--
          <div class="mt-8 md:mt-10 xl:mt-12 w-full flex justify-between pb-4 border-b border-gray-200 items-center">
            <p class="text-base font-medium leading-none text-gray-800 dark:text-white">
              Details
            </p>
            <button class="dark:bg-gray-400">
              <img class="w-6" src="https://tuk-cdn.s3.amazonaws.com/can-uploader/product_detail_svg2.svg" alt="add">
            </button>
          </div>
          <div class="mt-4 w-full flex justify-between pb-4 border-b border-gray-200 items-center">
            <p class="text-base font-medium leading-none text-gray-800 dark:text-white">
              Size chart
            </p>
            <button class="dark:bg-gray-400">
              <img class="w-6" src="https://tuk-cdn.s3.amazonaws.com/can-uploader/product_detail_svg2.svg" alt="add">
            </button>
          </div>
          -->
        </div>
      </div>
      <div
        class="mt-7 flex flex-col items-start border-gray-200 dark:border-gray-600 md:mt-0  md:w-2/3 md:px-6 md:py-12 lg:border-l xl:px-8"
      >
        <cgn-sell-product-cart-options v-slot="cart" :product="product">
          <div class="flex w-full items-center justify-between">
            <p class="text-xl font-medium leading-5 text-gray-800 dark:text-white xl:text-2xl xl:leading-normal">
              {{ product.name }}
            </p>
            <div
              v-if="cart.sku.on_special && cart.sku.in_stock"
              class="flex h-9 w-20 items-center justify-center rounded-full bg-yellow-100 text-center  text-base leading-none text-gray-800"
            >
              Sale
            </div>
          </div>
          <div class="mt-4 flex w-full items-center justify-between xl:mt-2">
            <div v-if="cart.sku.in_stock">
              <div class="flex items-center justify-start space-x-6">
                <p class="text-center text-xl leading-5 text-gray-800 dark:text-white">
                  ${{ cart.sku.price_each }}
                </p>
                <p v-if="cart.sku.on_special" class="text-center text-xl leading-5 text-gray-400 line-through">
                  ${{ cart.sku.full_price_each }}
                </p>
              </div>
              <p class="mt-1 text-xs">
                Or six payments of
                {{ laybuyPrice(cart.sku.price_each) }}
                <br>
                with
                <a href="https://www.laybuy.com/how-it-works" target="_blank">
                  <cgn-laybuy-logo class="inline" />
                  &nbsp;
                  <i-heroicons-solid:question-mark-circle class="inline" />
                </a>
              </p>
            </div>
            <div
              v-else
              class="flex h-9 w-20 items-center justify-center rounded-full bg-red-300 text-center  text-base leading-none text-gray-800"
            >
              Sold
            </div>
          </div>
          <!-- Start Variations -->
          <div v-for="variation in cart.variations" :key="variation.name" class="flex flex-col">
            <div class="mt-8 flex flex-col items-start justify-start space-y-5 xl:mt-6">
              <p
                class="text-center text-lg font-medium leading-none text-gray-800 dark:text-white xl:text-xl xl:leading-5"
              >
                {{ variation.name }}
              </p>
              <div class="flex items-center justify-start space-x-3">
                <cgn-button
                  v-for="option, index in variation.options" :key="index"
                  :class="option == variation.selected ? 'bg-slate-500 dark:bg-slate-500 text-white' : 'bg-slate-200 dark:bg-slate-700'"
                  @click="cart.selectVariation(variation.id, option)"
                >
                  {{ option }}
                </cgn-button>
              </div>
            </div>
          </div>
          <!-- End Variations -->
          <div
            v-if="false"
            class="mt-8 flex h-11 w-full items-center justify-between border border-gray-200 dark:border-gray-600 xl:mt-12"
          >
            <button
              class="flex h-full w-11 items-center justify-center border-r border-gray-200 dark:border-gray-600 dark:bg-gray-800 xl:w-16"
              @click="cart.decreaseQty()"
            >
              <i-heroicons-outline:minus />
            </button>
            <p id="num" class="text-xl leading-tight text-gray-600 dark:text-gray-200">
              {{ cart.qty }}
            </p>
            <button
              class="flex h-full w-11 items-center justify-center border-l border-gray-200 dark:border-gray-600 dark:bg-gray-800 xl:w-16"
              @click="cart.increaseQty()"
            >
              <i-heroicons-outline:plus />
            </button>
          </div>
          <div
            class="mt-6 flex w-full flex-col items-center justify-center space-y-4 xl:mt-10 xl:flex-row xl:space-x-8 xl:space-y-0"
          >
            <cgn-button v-if="cart.can_add_to_cart" color-brand fullwidth @click="cart.addToCart">
              Add to cart
            </cgn-button>
            <cgn-button v-if="cart.show_checkout" color-brand url="/checkout" fullwidth>
              Checkout
            </cgn-button>
          </div>
        </cgn-sell-product-cart-options>
        <div
          class="prose mt-6 text-base leading-normal text-gray-600 dark:prose-dark dark:text-gray-200 xl:mt-10"
          v-html="product.content"
        />
        <div class="mt-4">
          <strong>Please Note:</strong> On different devices colours may vary slightly.
        </div>
        <table class="mt-4 w-full">
          <tr v-if="product.medium">
            <td>
              Medium
            </td>
            <td>
              <strong>
                {{ product.medium }}
              </strong>
            </td>
          </tr>
          <tr v-if="product.dimension_height">
            <td>
              Height
            </td>
            <td>
              <strong>
                {{ product.dimension_height }} cm
                /
                {{ (product.dimension_height / 2.54).toFixed(1) }} in
              </strong>
            </td>
          </tr>
          <tr v-if="product.dimension_width">
            <td>
              Width
            </td>
            <td>
              <strong>
                {{ product.dimension_width }} cm
                /
                {{ (product.dimension_width / 2.54).toFixed(1) }} in
              </strong>
            </td>
          </tr>
          <tr v-if="product.dimension_depth">
            <td>
              Depth
            </td>
            <td>
              <strong>
                {{ product.dimension_depth }} cm
                /
                {{ (product.dimension_depth / 2.54).toFixed(1) }} in
              </strong>
            </td>
          </tr>
          <tr v-if="product.series">
            <td>
              Series
            </td>
            <td>
              <strong>
                {{ product.series }}
              </strong>
            </td>
          </tr>
        </table>
        <div class=" w-full flex-col items-start justify-start pb-12 md:hidden lg:flex">
          <div class="jusitfy-start mt-8 flex items-center space-x-4 xl:mt-10">
            <!-- Start Share -->
            <cgn-social-share
              :url="`/product/${product.slug}`" :title="product.name" :description="product.name"
              :image="product.image.url" :twitter="true" :facebook="true" :linkedin="true" class="mt-3 text-xl"
            />
            <!-- End Share -->
          </div>
          <!--
          <div class="mt-8 md:mt-10 xl:mt-12 w-full flex justify-between pb-4 border-b border-gray-200 items-center">
            <p class="text-base font-medium leading-none text-gray-800 dark:text-white">
              Details
            </p>
            <button class="dark:bg-gray-400">
              <img class="w-6" src="https://tuk-cdn.s3.amazonaws.com/can-uploader/product_detail_svg2.svg" alt="add">
            </button>
          </div>
          <div class="mt-4 w-full flex justify-between pb-4 border-b border-gray-200 items-center">
            <p class="text-base font-medium leading-none text-gray-800 dark:text-white">
              Size chart
            </p>
            <button class="dark:bg-gray-400">
              <img class="w-6" src="https://tuk-cdn.s3.amazonaws.com/can-uploader/product_detail_svg2.svg" alt="add">
            </button>
          </div>
          -->
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineProduct, useSchemaOrg } from '@vueuse/schema-org'
import { SanchiaProduct } from '~/models/SanchiaProduct'

class Templatevars {
}

const image_width = 300</script>

<script setup lang="ts">
const props = defineProps({
  templatevar: {
    type: Templatevars,
    required: true,
  },
  item: {
    type: String,
    required: true,
  },
})

const product = ref<SanchiaProduct>(new SanchiaProduct())

useSchemaOrg([
  defineProduct({
    image: computed(() => product.value.image.url),
    name: computed(() => product.value.name),
  }),
])

const image_index = ref(0)


const showVideo = ref(false)

const breadcrumbs = ref([{ name: 'Shop', url: '/shop' }])

useSchemaOrg([
  defineProduct({
    // name and description can usually be inferred
    name: computed(() => product.value?.name),
    description: computed(() => product.value?.blurb),
    image: computed(() => product.value?.image?.url),
    brand: {
      name: 'Sanchia Marshall',
    },
    offers: [
      defineOffer({
        priceCurrency: 'AUD',
        price: computed(() => product.value?.price.toFixed(2)),
        availability: computed(() => product.value?.in_stock ? 'InStock' : 'OutOfStock'),
      }),
    ],
  }),
])
function generateBreadcrumbs() {
  product.value.groups?.forEach((group) => {
    const breadcrumb = ref([{ name: 'Shop', url: '/shop' }])
    breadcrumb.value.push({ name: group.name, url: `/shop/${group.slug}` })
    breadcrumb.value.push({ name: product.value.name, url: product.value.slug })
    breadcrumbs.value.push(breadcrumb.value)
  })
}

async function loadProduct(url: string) {
  product.value.name = ''
  breadcrumbs.value = []
  const data = await new SanchiaProduct().find_one({
    url,
    image_aspect: '', // No cropping
    image_width,
    // base_url: '/api/v1/sanchia/product',
  })
  product.value = data
  generateBreadcrumbs()
  image_index.value = 0
}

function laybuyPrice(amount: number) {
  return Intl.NumberFormat('en-AU', {
    style: 'currency',
    currency: 'AUD',
  }).format(amount / 6)
}
function swapImage(index: number) {
  image_index.value = index
  showVideo.value = false
  window.scrollTo(0, 0)
}
function playVideo() {
  window.scrollTo(0, 0)
  showVideo.value = true
}

watch(() => props, (newUrl) => {
  loadProduct(newUrl.item)
}, {
  deep: true,
})
onMounted(() => {
  loadProduct(props.item)
})
onServerPrefetch(async () => {
  await loadProduct(props.item)
})
</script>
