<template>
  <div
    v-if="post.name"
  >
    <cgn-breadcrumb :links="breadcrumbs" />
    <cgn-page-builder-core-hero-quote :templatevar="{ heading: post.name, subheading: post.author, background_image: post.image.url, image_opacity: '50', parallax: true }" />

    <!-- Start Article -->
    <page-builder-content v-if="post.rows.length" :pagebuilder-rows="post.rows" contained-class="xx" />
    <div v-else class="2xl:mx-auto lg:py-16 lg:px-52 md:py-12 md:px-6 py-9 px-2">
      <div class="font-normal text-base leading-6 text-gray-600 dark:text-gray-400 mt-6 text-justify prose dark:prose-dark max-w-none" v-html="post.content" />
    </div>
    <!-- End Article -->

    <!-- Start Share -->
    <div class="2xl:mx-auto lg:py-16 lg:px-52 md:py-12 md:px-6 py-9 px-2">
      <cgn-social-share
        :url="`/article/${post.slug}`"
        :title="post.name"
        :description="post.name"
        :image="post.image.url"
        class="mt-3 text-xl"
        twitter
        facebook
        linkedin
      />
      <!-- End Share -->
    </div>
  </div>
</template>

<script lang="ts">
import { CognitoArticle } from '~cognito/models/Cognito/Article'

class Templatevars {
}

const image_width = 1920</script>

<script setup lang="ts">
const props = defineProps({
  templatevar: {
    type: Templatevars,
    required: true,
  },
  item: {
    type: String,
    required: true,
  },
})
const post = ref<CognitoArticle>(new CognitoArticle())

useSchemaOrg([
  defineArticle({
    // name and description can usually be inferred
    image: computed(() => post.value?.image?.url),
    // datePublished: new Date(2020, 1, 1),
    // dateModified: new Date(2020, 1, 1),
  }),
])



const breadcrumbs = ref([{ name: 'Blog', url: '/blog' }])

async function loadArticle(url: string) {
  post.value.name = ''
  breadcrumbs.value = [{ name: 'Blog', url: '/blog' }]
  const data = await new CognitoArticle().find_one({
    url,
    image_aspect: '16x9',
    image_width,
  })
  post.value = data
  if (post.value.groups?.length > 0) {
    breadcrumbs.value.push({ name: post.value.groups[0].name, url: `/blog/${post.value.groups[0].slug}` })
  }
  breadcrumbs.value.push({ name: post.value.name, url })
}

watch(() => props.item, (newUrl) => {
  loadArticle(newUrl)
})
onMounted(() => {
  loadArticle(props.item)
})
onServerPrefetch(async () => {
  await loadArticle(props.item)
})
</script>
