<template>
  <div class="relative">
    <h1 class="text-3xl font-semibold">
      {{ props.templatevar.heading }}
    </h1>
  </div>
</template>

<script lang="ts">
class Templatevars {
  heading?: string
}
</script>

<script setup lang="ts">
const props = defineProps({
  templatevar: {
    type: Templatevars,
    required: true,
  },
})
</script>
