<template>
  <div v-if="widget.outer == 'tpl/heading'">
    <page-builder-heading :templatevar="widget.templatevar" />
  </div>
  <div v-else-if="widget.outer == 'tpl/heading_and_content'">
    <page-builder-heading-content :templatevar="widget.templatevar" />
  </div>
  <div v-else-if="widget.outer == 'Microblog/feed'">
    <cgn-page-builder-microblog-feed :templatevar="widget.templatevar" dateformat="d MMM Y" :tag="props.urlParts.item_url" feedurl="/feed" image-aspect="1x1" class="mx-auto max-w-sm px-4 md:px-2" />
  </div>
  <div v-else-if="widget.outer == 'Blog/article_list'">
    <page-builder-article-list :templatevar="widget.templatevar" :group="props.urlParts.item_url" :page="props.urlParts.url_parameter_2" />
  </div>
  <div v-else-if="widget.outer == 'Blog/article_view'">
    <page-builder-article-view :templatevar="widget.templatevar" :item="props.urlParts.item_url" />
  </div>
  <div v-else-if="widget.outer == 'tpl/shop'">
    <page-builder-tpl-shop :templatevar="widget.templatevar" :group="props.urlParts.item_url" :page="props.urlParts.url_parameter_2" />
  </div>
  <div v-else-if="widget.outer == 'Podcast/view'">
    <cgn-podcast-feed :channel="1" class="mx-auto max-w-7xl" />
  </div>
  <div v-else-if="widget.outer == 'Podcast/episode'">
    <cgn-podcast-episode :templatevar="widget.templatevar" :item="props.urlParts.item_url" :class="props.containedClass" />
  </div>
  <div v-else-if="widget.outer == 'tpl/sell_product_view'">
    <page-builder-tpl-sell-product-view :templatevar="widget.templatevar" :item="props.urlParts.item_url" />
  </div>
  <cgn-page-builder v-else :widget="widget" :url-parts="urlParts" :contained-class="props.containedClass" />
</template>

<script setup lang="ts">
import { CognitoUrlParts } from '~cognito/models/Cognito/Page'

const props = defineProps({
  widget: {
    required: true,
  },
  containedClass: {
    type: String,
    default: 'px-6 py-2 mx-auto max-w-5xl',
  },
  urlParts: {
    type: CognitoUrlParts,
    required: true,
  },
})
</script>
