import { config } from '../cognitocmsapi/default_config.js'

config.devURL = `https://sanchia.${config.devbase}`
config.prodURL = 'https://cms.sanchia.com.au'
config.siteURL = 'https://artbysanchia.com'
config.baseURL = config.devbase == '' ? config.prodURL : config.devURL

config.group_args.image_aspect = '4x3'

config.isEcommerce = true
config.sentryDsn = 'https://fa6bc4991acc4aa294109e66a18a4ae3@glitchtip.logger.jm1.au/4'

export { config }
