import { SellProduct } from '~cognito/models/Sell/Product'

class SanchiaProduct extends SellProduct {
  year_painted: string
  medium: string
  dimension_width: string
  dimension_height: string
  dimension_depth: string
  series: string

  constructor(source?: Partial<SanchiaProduct>) {
    super()
    this.year_painted = ''
    this.medium = ''
    this.dimension_width = ''
    this.dimension_height = ''
    this.dimension_depth = ''
    this.series = ''
    Object.assign(this, source)
  }

  baseurl(): string {
    return '/api/v1/sanchia/product'
  }
}

export { SanchiaProduct }
