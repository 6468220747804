<template>
  <cgn-breadcrumb :links="breadcrumbs" class="hidden md:block" />

  <div class="py-16 sm:py-24 md:py-44 lg:py-20 px-4 text-gray-800 dark:text-gray-200 ">
    <div class="">
      <h1 class="xl:text-5xl pt-4 xl:pt-0 text-3xl text-center font-extrabold mb-4">
        Latest Articles
      </h1>
      <p class="text-xl text-center xl:w-3/5 mx-auto w-11/12">
        {{ groupDetails.name }}
      </p>
    </div>
    <div class="mx-auto mt-12 grid max-w-lg gap-5 md:max-w-none md:grid-cols-2 lg:grid-cols-3">
      <div
        v-for="post, index in posts"
        :key="index"
        class="flex flex-col overflow-hidden rounded-lg shadow-lg"
      >
        <div class="shrink-0">
          <router-link :to="`/article/${post.slug}`">
            <cgn-lazy-image
              class="w-full"
              :image="post.image"
            />
          </router-link>
        </div>
        <div class="flex flex-1 flex-col justify-between bg-white p-6 dark:bg-darkbg-500">
          <div class="flex-1">
            <router-link :to="`/article/${post.slug}`" class="mt-2 block">
              <p class="text-xl font-semibold text-gray-900 dark:text-gray-100">
                {{ post.name }}
              </p>
              <div class="mt-3 text-base text-gray-500 dark:text-gray-200" v-html="post.blurb" />
            </router-link>
          </div>
          <div class="mt-6 flex items-center justify-between">
            <div class="ml-3">
              <p class="text-sm font-medium text-gray-900 dark:text-gray-100">
                {{ post.author }}
              </p>
              <div class="text-secondary-500 text-xs">
                <i-heroicons-outline:clock class="inline" />
                {{ post.reading_time }} minute read
              </div>
            </div>
            <div class="text-right mt-1">
              <cgn-button color-brand :url="`/article/${post.slug}`">
                Read Now
              </cgn-button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="flex flex-grow justify-center py-5">
      <cgn-pagination
        :current-page="page"
        :page-count="num_pages"
        :group="group"
        url-prefix="blog"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { CognitoGroup } from '~cognito/models/Cognito/Group'
import { CognitoArticle } from '~cognito/models/Cognito/Article'

class Templatevars {
}
</script>

<script setup lang="ts">
const props = defineProps({
  templatevar: {
    type: Templatevars,
    required: true,
  },
  group: {
    type: String,
  },
  page: {
    type: String,
  },
})

const posts = ref<CognitoArticle[]>([])
const groupDetails = ref<CognitoGroup>(new CognitoGroup())
const num_pages = ref(0)

const breadcrumbs = ref([{ name: 'Articles', url: '/blog' }])

async function loadArticles() {
  posts.value = []
  num_pages.value = 1
  let group = props.group
  const page = props.page
  if (group === 'page') {
    group = ''
  }
  groupDetails.value = new CognitoGroup()
  breadcrumbs.value = [{ name: 'Articles', url: '/blog' }]
  const articles = await new CognitoArticle().find_many({
    image_aspect: '1x1',
    image_width: 600,
    page_size: 9,
    page,
    group,
  })
  posts.value = articles.data
  num_pages.value = articles.num_pages

  if (group) {
    // Need the group to get heading etc
    const groupData = await new CognitoGroup().find_one({
      namespace: 'Cognito',
      model: 'Article',
      group,
    })
    groupDetails.value = groupData
    breadcrumbs.value.push({ name: groupData.name, url: group })
  }
}
watch(() => props, () => {
  loadArticles()
}, {
  deep: true,
})
onMounted(() => {
  loadArticles()
})
onServerPrefetch(async () => {
  await loadArticles()
})
</script>
