<template>
  <div class="py-12 px-4 md:px-6 xl:px-20">
    <ProductGrid v-if="props.group" :group="props.group" :page="props.page" />
    <div v-else>
      <h1 class="text-xl dark:text-white xl:text-2xl font-medium leading-5 xl:leading-normal text-gray-800">
        Shop
      </h1>
      <div class="pt-4">
        <div class="w-full grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
          <!-- Start Group -->
          <div
            v-for="group in groups"
            :key="group.slug"
            class="bg-white dark:bg-gray-700 rounded-lg shadow hover:shadow-xl p-5 xl:p-4"
          >
            <router-link
              :to="`/shop/${group.slug}`"
            >
              <cgn-lazy-image
                class="w-full rounded-md"
                :image="group.image"
              />
              <div class="mt-3">
                {{ group.name }}
              </div>
            </router-link>
          </div>
          <!-- End Group -->
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { CognitoGroup } from '~cognito/models/Cognito/Group'

class Templatevars {
}
</script>

<script setup lang="ts">
const props = defineProps({
  templatevar: {
    type: Templatevars,
    required: true,
  },
  group: {
    type: String,
  },
  page: {
    type: String,
  },
})

const groups = ref<CognitoGroup[]>([])

async function loadGroup() {
  const data = await new CognitoGroup().find_one({
    namespace: 'Sell',
    model: 'Product',
    group: 'range-level',
    image_aspect: 'Square',
    image_width: 600,
  })
  groups.value = data.children
}

onMounted(() => {
  loadGroup()
})

onServerPrefetch(async () => {
  await loadGroup()
})
</script>
