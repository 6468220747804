<template>
  <cgn-router-view />
</template>

<script setup lang="ts">
useSchemaOrg([
  // @todo Select Identity: https://unhead-schema-org.harlanzw.com//guide/guides/identity
  definePerson({
    name: 'Sanchia Marshall',
  }),
  defineWebSite({
    name: 'Art by Sanchia',
  }),
  defineWebPage(),
])

useHead({
  link: [
    {
      rel: 'preconnect',
      href: 'https://cms.sanchia.com.au',
    },
    {
      rel: 'preconnect',
      href: 'https://cdn.jm1.au',
    },
  ],
})
</script>
