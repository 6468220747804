<template>
  <div class="relative p-6 sm:p-12 space-y-2 max-w-3xl mx-auto text-justify">
    <h1 v-if="props.templatevar.heading" class="text-3xl font-semibold py-4">
      {{ props.templatevar.heading }}
    </h1>
    <cgn-lazy-image v-if="header_image" :image="header_image" />
    <div class="prose dark:prose-dark max-w-none" v-html="props.templatevar.text" />
  </div>
</template>

<script lang="ts">
import { CognitoImage } from '~cognito/models/Cognito/Image'

class Templatevars {
  heading?: string
  text?: string
  header_image?: number
}
</script>

<script setup lang="ts">
const props = defineProps({
  templatevar: {
    type: Templatevars,
    required: true,
  },
})
const header_image = ref<CognitoImage>()
async function loadImage() {
  if (!props.templatevar.header_image) {
    return
  }
  header_image.value = await new CognitoImage().find_one_mapped({
    url: props.templatevar.header_image,
    image_aspect: '16x9',
    image_width: 1920,
  })
}
watch(() => props, () => {
  loadImage()
},
{ deep: true })
onMounted(async () => {
  await loadImage()
})
onServerPrefetch(async () => {
  await loadImage()
})
</script>
