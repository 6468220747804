<template>
  <div v-if="products.length">
    <!--
    <cgn-breadcrumb :links="breadcrumbs" class="hidden md:block" />
    -->

    <div>
      <div v-if="!hideHeading">
        <h1 class="text-xl dark:text-white xl:text-2xl font-medium leading-5 xl:leading-normal text-gray-800">
          Shop
          {{ groupDetails.name }}
        </h1>
        <div class="prose prose-indigo dark:prose-dark text-sm self-center pt-4" v-html="groupDetails.content" />
      </div>
      <div>
        <div class="w-full grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
          <!-- Start Product -->
          <div
            v-for="product in products"
            :key="product.slug"
            class="bg-white dark:bg-gray-700 rounded-lg shadow hover:shadow-xl p-5 xl:p-4"
          >
            <router-link
              :to="`/product/${product.slug}`"
            >
              <cgn-lazy-image
                class="w-full rounded-md"
                :image="product.image"
              />
              <div class="mt-3">
                {{ product.name }}
              </div>
              <div v-if="product.in_stock">
                ${{ product.price.toFixed(2) }}
              </div>
              <div v-else class="text-red-500">
                Sold
              </div>
            </router-link>
          </div>
        <!-- End Product -->
        </div>
      </div>
    </div>

    <div class="flex flex-grow justify-center py-5">
      <cgn-pagination
        :current-page="props.page"
        :page-count="num_pages"
        :group="props.group"
        url-prefix="shop"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { SellProduct } from '~cognito/models/Sell/Product'
import { CognitoGroup } from '~cognito/models/Cognito/Group'

const props = defineProps({
  group: {
    type: String,
    default: 'page',
  },
  pageSize: {
    type: Number,
    default: 12,
  },
  page: {
    type: Number,
    default: 1,
  },
  hideHeading: {
    type: Boolean,
    default: false,
  },
})

const products = ref<SellProduct[]>([])

const groupDetails = ref<CognitoGroup>(new CognitoGroup())
const num_pages = ref(0)

const breadcrumbs = ref([{ name: 'Shop', url: '/shop' }])

async function loadProducts() {
  products.value = []
  num_pages.value = 1
  let group = props.group
  const page = props.page
  if (group === 'page') {
    group = ''
  }
  breadcrumbs.value = [{ name: 'Shop', url: '/shop' }]
  const data = await new SellProduct().find_many({
    image_aspect: 'Square',
    image_width: 600,
    page_size: props.pageSize,
    orderby: 'latest_in_stock',
    page,
    group,
  })
  products.value = data.data
  num_pages.value = data.num_pages

  if (group) {
    // Need the group to get heading etc
    const data = await new CognitoGroup().find_one({
      namespace: 'Sell',
      model: 'Product',
      group,
    })
    groupDetails.value = data
    breadcrumbs.value.push({ name: data.name, url: group })
  }
}
watch(() => props, () => {
  loadProducts()
}, {
  deep: true,
})
onMounted(() => {
  loadProducts()
})
onServerPrefetch(async () => {
  await loadProducts()
})
</script>
